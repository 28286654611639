$color-black: #111311;
$color-black-2: lighten($color-black, 5%);
$color-white: #f0f7ff;

$color-gray: #999;
$color-gray-lt: lighten($color-gray, 20%);
$color-gray-dk: dakren($color-gray, 20%);

$color-blue: #3b6ea5;

$color-green: #445626;
$color-green-lt: lighten($color-green, 10%);
$color-green-dk: darken($color-green, 10%);
$color-green-black: #032313;

$color-green2: #64a646;
$color-green2-lt: lighten($color-green2, 20%);
$color-green2-dk: darken($color-green2, 20%);

$color-gunmetal: #093545;
$color-gunmetal-lt: lighten($color-gunmetal, 10%);
$color-gunmetal-dk: darken($color-gunmetal, 10%);

$color-orange: #ff8700;
$color-orange-lt: lighten($color-orange, 20%);
$color-orange-dk: darken($color-orange, 20%);

$color-red: #b80c09;
$color-red-lt: lighten($color-red, 20%);
$color-red-dk: darken($color-red, 20%);


$color-pink: #F72585;

// ------------------------------

$color_site-background: linear-gradient(34deg, $color-black, $color-black-2);
$color_text-lt: $color-white;
$color_text-dk: $color-black-2;
$color_header-lt: $color-blue;
$color_header-dk: $color-green-dk;
$color_footer-lt: $color-gunmetal;

$color_link: $color-green2;
$color_link-hover: $color-green2-lt;
$color_link-active: $color-green2-lt;
$color_link-visited: $color-green2;
$color_link-disabled: $color-gray;

$color_button-background: $color-green;
$color_button-background-hover: $color-green-lt;
$color_button-background-active: $color-green-lt;
$color_button-background-disabled: $color-gray;
$color_button-text: $color-white;
$color_button-text-hover: $color-white;
$color_button-text-active: $color-white;
$color_button-text-disabled: $color-gray-lt;
$color_button-outline: $color-pink;

$color_textbox-background: $color-gunmetal;
$color_textbox-text: $color-white;
$color_textbox-border: $color-green;
