@use '../../scss/utility';

.container {
  position: relative;

  .menu-container-load {
    transform-style: preserve-3d; // NOTE: preserve3d causes artifacts on cube rotation
    transform-style: flat;
    perspective: 4000px;
    pointer-events: none;
    
    .menu-container-wait {
      //transform-style: preserve-3d;
      pointer-events: all;
    }
  }
}