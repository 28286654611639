@use "sass:map";
@use "./colors";

$breakpoints: (
  "zero": 0,
  "min": 300,
  "xs": 400,
  "sm": 600,
  "md": 840,
  "lg": 1024,
  "xl": 1280,
  "ws": 1440,
  "hd": 1920,
);
$portraitKey: "portrait";
$landscapeKey: "landscape";

@mixin media-breakpoint-gte($bp) {
  $minWidth: map.get($breakpoints, $bp) + 0px;
  @media (min-width: $minWidth) {
    @content;
  }
}

@mixin flex-row-center($gap: "1rem", $margin: "0") {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: $gap;
  margin: $margin;
}

@mixin text-shadow($color: colors.$colorTextShadowLt) {
  text-shadow: 0px 0px 2px $color;
}
@mixin box-shadow($color: colors.$colorBoxShadow) {
  box-shadow: 0px 0px 8px 0px $color;
}
////////////////////////////////////////
// Potentially reusable functionality
////////////////////////////////////////
//
// Generate a new map with each value modified by scalar
//
@function mapApplyScalar($scalar, $map) {
  $mapped: ();
  @each $key, $val in $map {
    $mappedVal: $val * $scalar;
    $mapped: map.set($mapped, $key, $mappedVal);
  }
  @return ($mapped);
}
//
// Wrap the values of a map with $modifier and parentheses
// - e.g. "translateZ" -> "translateZ(<val>)"
//,
@function mapWrapValues($modifier, $map) {
  $mapped: ();
  @each $key, $val in $map {
    $mappedVal: "#{$modifier}(#{$val})";
    //@warn "5 ### " + $mappedVal;
    $mapped: map.set($mapped, $key, $mappedVal);
  }
  //@warn "4 ### " + inspect($mapped);
  @return ($mapped);
}
//
// Generates a set of media-queries for the provided property and list of values based on $breakpoints array
//
@mixin breakpointProp($prop, $valuesList) {
  // Default to min value
  #{$prop}: #{map.get($map: $valuesList, $key: "zero")};
  // For each provided bp generate a value for prop in respective media query
  @each $key, $val in $valuesList {
    @include media-breakpoint-gte($key) {
      #{$prop}: #{$val};
    }
  }
}
//
// Generates a set of media-queries for the provided property and list of values based on $breakpoints array
// - Expected format:
//   map: (
//     <property>: (
//       <breakpoint>: <value>
//
@mixin breakpointProps($propsMap) {
  // To avoid repeated media-queries for each prop, build a map of properties at each bp
  $mediaQueryMap: ();
  // Loop all breakpoints and add any props with value at bp
  @each $bp in $breakpoints {
    // list of props in current bp
    $bpProps: ();
    // bp key text, e.g. "xs"
    $bpKey: nth(
      $list: $bp,
      $n: 1,
    );
    // Loop all provided props and add value if one exists for bp
    @each $prop, $valueLists in $propsMap {
      @if map.has-key($valueLists, $bpKey) {
        $valueAtBp: map.get($valueLists, $bpKey);
        $bpProps: map.set($bpProps, $prop, $valueAtBp);
      }
    }
    $mediaQueryMap: map.set($mediaQueryMap, $bpKey, $bpProps);
  }

  @each $breakpoint, $propsList in $mediaQueryMap {
    //
    // TODO: defaut value w/o media-query?
    //
    @include media-breakpoint-gte($breakpoint) {
      @each $property, $value in $propsList {
        #{$property}: #{$value};
      }
    }
  }
}
//
// Generate a set of properties for orientations and breakpoints
//
@mixin orientationBpProp($property, $orientations) {
  @each $orientation, $breakpointList in $orientations {
    //
    // TODO: defaut value w/o media-query?
    //
    @media screen and (orientation: #{$orientation}) {
      @each $breakpoint, $valuesList in $breakpointList {
        @include media-breakpoint-gte($breakpoint) {
          @each $value in $valuesList {
            #{$property}: #{$value};
          }
        }
      }
    }
  }
}
//
// Generate an orientation-breakpoint media-query set from each property in $map
//  Expected format:
//    (property)
//      (orientation)
//        (breakpoint | value)
//
@mixin orientationBpProps($map) {
  // for each property, check for orientation values and create a set of media queries for breakpoints
  @each $property, $orientations in $map {
    @include orientationBpProp($property, $orientations);
  }
}
//
// Loop over each orientation's breakpoint value map
// - NOTE: Must include "using ($bp,$val)" at the end of the mixin call
//
@mixin orientationBpEach($map) {
  @each $orientation, $map in $map {
    @each $bp, $val in $map {
      @content ($bp, $val);
    }
  }
}
//
// Apply a scalar to a nested portrait/landscape breakpoint map
//
@function getScaledOrientationBpMap($scalar, $map) {
  @if not map.has-key($map, $portraitKey) {
    @error "Map missing portrait values";
  }
  @if not map.has-key($map, $landscapeKey) {
    @error "Map missing landscape values";
  }
  $portrait: map.get($map, $portraitKey);
  $landscape: map.get($map, $landscapeKey);
  $mapPortrait: mapApplyScalar($scalar, $portrait);
  $mapLandscape: mapApplyScalar($scalar, $landscape);
  $newMap: (
    "portrait": $mapPortrait,
    "landscape": $mapLandscape,
  );
  @return $newMap;
}
//
// Wrap values of a nested portrait/landscape breakpoint map
// NOTE: Assumes format of "modifier(value)" with parentheses
//
@function getWrappedOrientationBpMap($modifier, $map) {
  @if not map.has-key($map, $portraitKey) {
    @error "Map missing portrait values";
  }
  @if not map.has-key($map, $landscapeKey) {
    @error "Map missing landscape values";
  }
  $portrait: map.get($map, $portraitKey);
  $landscape: map.get($map, $landscapeKey);

  $mapPortrait: mapWrapValues($modifier, $portrait);
  $mapLandscape: mapWrapValues($modifier, $landscape);
  $newMap: (
    "portrait": $mapPortrait,
    "landscape": $mapLandscape,
  );
  @return $newMap;
}

///////////////////////////////////////
// UI Elements - specific to site
///////////////////////////////////////
//
// Creates an ellipse of $color with highlight of $color-lt and shadow of $color-dk
//
@mixin logoEllipse($color, $color-lt, $color-dk) {
  background-color: $color;

  &::before {
    content: "";
    z-index: -1;
    position: absolute;
    $offsetV: -5%;
    $offsetH: -4%;
    top: $offsetV;
    bottom: $offsetV;
    right: $offsetH;
    left: $offsetH;
    border-radius: 50%;
    background-color: $color;
    background-image: linear-gradient(
      180deg,
      $color-lt 42%,
      $color 54%,
      $color-dk 66%
    );
  }
}
//
// Create text logo
//
@mixin textLogo(
  $logoSizeList,
  $fontSizeScalarFishbone,
  $fontSizeScalarDigital,
  $topOffsetScalarDigital,
  $rightOffsetScalarDigital
) {
  position: relative;
  margin: 0;
  $fontSizesFishbone: mapApplyScalar($fontSizeScalarFishbone, $logoSizeList);
  .fishbone {
    position: absolute;
    top: 0;
    right: 0;
    @include breakpointProp("font-size", $fontSizesFishbone);
    font-family: "Comforter", cursive;
    color: colors.$color-pink;
  }
  .digital {
    position: absolute;
    // Generate property maps relative to logo sizes
    $digitalSizes: mapApplyScalar(
      $scalar: $fontSizeScalarDigital,
      $map: $fontSizesFishbone,
    );
    $digitalTops: mapApplyScalar(
      $scalar: $topOffsetScalarDigital,
      $map: $fontSizesFishbone,
    );
    $digitalRights: mapApplyScalar(
      $scalar: $rightOffsetScalarDigital,
      $map: $fontSizesFishbone,
    );
    $propMap: (
      "font-size": $digitalSizes,
      "top": $digitalTops,
      "right": $digitalRights,
    );
    @include breakpointProps($propMap);
  }
}
