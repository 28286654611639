@use 'sass:math';
@use '../../../scss/utility';

// CONFIG

// Size of svg logo for each breakpoint
$logoSizeList: (
  "zero": 36vw,
  "min": 32vw,
  "xs": 28vw,
  "sm": 24vw,
  "md": 18vw,
  "lg": 15vw,
  "xl": 14vw,
  "ws": 12vw,
  "hd": 8vw,
);
// Size of text logo is based on % of svg logo
$logoTextFishboneFontSizeScalar: 0.42;
$logoTextDigitalFontSizeScalar: 0.69;
$logoTextDigitalTopScalar: 0.84;
$logoTextDigitalRightScalar: 0.24;

// End CONFIG
///////////////////////////////

//
.site-header {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
  .logo-container {
    position: relative;
    display: inline-block;
    margin: 1vw;
    border-radius: 50%;
    height: 100%; // maintain logo ellipse aspect
    @include utility.logoEllipse(utility.$color-gunmetal, utility.$color-gunmetal-lt, utility.$color-gunmetal-dk);
    
    &.logo-construction {
      @include utility.logoEllipse(utility.$color-orange, utility.$color-orange-lt, utility.$color-orange-dk);
    }
    &.logo-error {
      @include utility.logoEllipse(utility.$color-red, utility.$color-red-lt, utility.$color-red-dk);
    }
    
    .logo {
      position: relative;
      top: 0.15rem;
      @include utility.breakpointProp('width', $logoSizeList);
    }
  }
  .title {
    @include utility.textLogo(
      $logoSizeList,
      $logoTextFishboneFontSizeScalar,
      $logoTextDigitalFontSizeScalar,
      $logoTextDigitalTopScalar,
      $logoTextDigitalRightScalar,
    );
  }
}