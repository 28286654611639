@use 'sass:math';
@use '../../../scss/utility';

footer {
  position: relative;
  max-width: 100vw;
  margin-top: 3rem;
  
  $waveVerticalOffset: 5vw;
  @include utility.media-breakpoint-gte(md) {

  }
  @include utility.media-breakpoint-gte(lg) {
    $waveVerticalOffset: 2vw;
  }
  @include utility.media-breakpoint-gte(ws) {
    $waveVerticalOffset: 1vw;
  }
  @keyframes translateX {
    0% { background-position-x: 0; background-position-y: 0; }
    10% { background-position-y: $waveVerticalOffset; }
    20% { background-position-y: 0px; }
    30% { background-position-y: $waveVerticalOffset; }
    40% { background-position-y: 0px; }
    50% { background-position-y: $waveVerticalOffset; }
    60% { background-position-y: 0px; }
    70% { background-position-y: $waveVerticalOffset; }
    80% { background-position-y: 0px; }
    90% { background-position-y: $waveVerticalOffset; }
    100% { background-position-x: 100%; background-position-y: 0; }
  }

  $waveHeight: 6vw;
  > div {
    position: absolute;
    bottom: 0;
    min-height: $waveHeight;
    @include utility.media-breakpoint-gte(xs) {
      min-height: $waveHeight;
    }
    @include utility.media-breakpoint-gte(md) {
      min-height: 5vw;
    }
    @include utility.media-breakpoint-gte(xl) {
      min-height: 4vw;
    }
    width: 100%;
    background-image: url('../../../assets/images/wave1.svg');
    background-size: 14.25%;
    background-repeat: repeat-x;
    //transition: background-position-x 1s linear;
    animation: translateX 19s linear infinite backwards;
    
    &.set2 {
      top: -8vw;//-20px;
      @include utility.media-breakpoint-gte(md) {
        top: -8vw//-44px;
      }
      @include utility.media-breakpoint-gte(xl) {
        top: -6vw//-60px;
      }
      background-position-x: 0px;
      background-size: 11.1%;
      background-image: url('../../../assets/images/wave2.svg');
      animation: translateX 22s linear infinite backwards;
    }
    &.set3 {
      top: -9vw;//-30px;
      @include utility.media-breakpoint-gte(md) {
        top: -10vw;//-50px;
      }
      @include utility.media-breakpoint-gte(xl) {
        top: -7vw//-70px;
      }
      background-position-x: 0px;
      background-size: 7.13%;
      background-image: url('../../../assets/images/wave3.svg');
      animation: translateX 25s linear infinite backwards;
    }
  }
}