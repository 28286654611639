@use './mixins';

// @TODO - font faces and local files when done deciding
$font-scale: 1.5;

html, body {
  font-family: 'Mulish', sans-serif;
}

h1, h2, h3, h4, h5 {
  font-family: 'Economica', sans-serif;
  font-weight: normal;
}

h1 { font-size: 2.2rem * $font-scale; }
h2 { font-size: 1.8rem * $font-scale; }
h3 { font-size: 1.5rem * $font-scale; }
h4 { font-size: 1.25rem * $font-scale; }
h5 { font-size: 1rem * $font-scale; }

@include mixins.media-breakpoint-gte(lg) {
  h1 { font-size: 2.25rem * $font-scale; }
  h2 { font-size: 2.0rem * $font-scale; }
  h3 { font-size: 1.75rem * $font-scale; }
  h4 { font-size: 1.5rem * $font-scale; }
  h5 { font-size: 1.25rem * $font-scale; }
}

p {
  font-size: 1rem;
  font-family: 'Mulish', sans-serif;
}
button {
  font-family: 'Share Tech', sans-serif;
  font-size: 1.25rem;
  font-weight: normal;
  text-transform: uppercase;
}
input[type='text'] {
  font-size: 0.9rem;
}