@use './colors';

%Outline {
  outline: none;
  outline-offset: 2px;
  &:focus {
    outline: 2px dashed colors.$color_button-outline
  }
}

$border-radius: 0.2rem;

////////////////////////////////////////////////


a {  
  text-decoration: none;
  color: colors.$color_link;
  border-radius: $border-radius;

  &:visited {
    color: colors.$color_link-visited;
  }
  &:hover {
    color: colors.$color_link-hover;
  }
  &:active {
    color: colors.$color_link-active;
  }
  @extend %Outline;
}

button {
  padding: 0.45rem 0.8rem;
  border: none;
  border-radius: $border-radius;
  background-color: colors.$color_button-background;
  color: colors.$color_button-text;

  &:hover {
    background-color: colors.$color_button-background-hover;
    color: colors.$color_button-text-hover;
  }
  &:active {
    background-color: colors.$color_button-background-active;
    color: colors.$color_button-text-active;
  }
  &:disabled {
    background-color: colors.$color_button-background-disabled;
    color: colors.$color_button-text-disabled;
  }
  @extend %Outline;
}

input[type="text"] {
  padding: 0.5rem 0.75rem;
  border: 1px solid colors.$color_textbox-border;
  border-radius: $border-radius;
  background-color: colors.$color_textbox-background;
  color: colors.$color_textbox-text;
  @extend %Outline;
}