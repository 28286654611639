@use './colors';
@use './mixins';

* {
  box-sizing: border-box;
}
html {
  font-size: 100%;
  @include mixins.media-breakpoint-gte(xs) { font-size: 106%; }
  @include mixins.media-breakpoint-gte(sm) { font-size: 112%; }
  @include mixins.media-breakpoint-gte(md) { font-size: 118%; }
  @include mixins.media-breakpoint-gte(lg) { font-size: 124%; }
  @include mixins.media-breakpoint-gte(xl) { font-size: 130%; }
  @include mixins.media-breakpoint-gte(ws) { font-size: 136%; }
  @include mixins.media-breakpoint-gte(hd) { font-size: 142%; }
}
html, body {
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  background: colors.$color_site-background;
  color: colors.$color_text-lt;
}

h1, h2, h3, h4, h5 {
  margin: 0.8em 0 0.3rem; // relative to font-size
  color: colors.$color_header-lt;
}
p {
  margin: 0.5em 0; // relative to font-size
  color: colors.$color_text-lt;
}
