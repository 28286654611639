@use 'sass:math';
@use './scss/utility';
@use './scss/App.config' as config;

.site {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  main {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include utility.media-breakpoint-gte(sm) {
      justify-content: center;
    }
    padding: 0 1rem;
    margin: 0 auto;
  }
}
