@use 'sass:math';
@use 'sass:map';
@use '../../scss/utility';

$keyPortrait: "portrait";
$keyLandscape: "landscape";
$keyDimensions: "dimensions";
$keyConstruction: "construction";
$keyLocation: "location";
// NOTE: value MUST match a CSS transform property value
$keyTranslateZ: "translateZ";

// Convert val to vmin units
@function minSize($val) {
  @return $val + 0vmin;
}
// Size of cube at viewport breakpoints
$cubeSizes: (
  portrait: (
    // 0px -> 299px
    zero: minSize(90),
    // 300
    min: minSize(80),
    // 400
    xs: minSize(76),
    // 600
    sm: minSize(70),
    // 840
    md: minSize(60),
    // 1024,
    lg: minSize(44),
    // 1280
    xl: minSize(36),
    // 1440,
    ws: minSize(30),
    // 1920,
    hd: minSize(26),
  ),
  landscape: (
    zero: minSize(90),
    min: minSize(80),
    xs: minSize(76),
    sm: minSize(70),
    md: minSize(66),
    lg: minSize(60),
    xl: minSize(56),
    ws: minSize(52),
    hd: minSize(50),
  ),
);

// By default, each side is translated half of the width of a side, so the cube originates from its center at 0,0,0
$cubeSizeTranslateZScalar: 0.5;
$cubeSizeZAmounts: utility.getScaledOrientationBpMap(
  $scalar: $cubeSizeTranslateZScalar,
  $map: $cubeSizes,
);
// Distance of cube side from origin, face is first rotated and then translated along the (new) z-axis this distance to form the cube
$cubeSideZTranslations: utility.getWrappedOrientationBpMap(
  $modifier: $keyTranslateZ,
  $map: $cubeSizeZAmounts,
);

// Move the entire cube the reverse amount of face translation so overall size on screen matches with of cube var
$cubePositionTranslateZScalar: -0.5;
$cubePositionZAmounts: utility.getScaledOrientationBpMap(
  $scalar: $cubePositionTranslateZScalar,
  $map: $cubeSizes,
);
// Transform-translate entire cube backwards
$cubePositionZTranslations: utility.getWrappedOrientationBpMap(
  $modifier: $keyTranslateZ,
  $map: $cubePositionZAmounts,
);
// Collection of media queries for properties of Menu Cube
$cubeProps: (
  "dimensions": (
    "width": $cubeSizes,
    "height": $cubeSizes,
  ),
  "construction": (
    "transform": $cubeSideZTranslations,
  ),
  "location": (
    "transform": $cubePositionZTranslations,
  ),
);
// TODO: redundant? Get the same info if map.get() fails?
@function getMapCubeProps($key) {
  @if not map.get($cubeProps, $key) {
    @error "Missing #{$key} in $cubeSizes config";
  }
  @return map.get($cubeProps, $key);
}
// Cube is centered at 0,0,0 - offset is half of cube size at bp
@function getCubeFaceOffset($cubeSize) {
  @return math.div($cubeSize, 2);
}
// Include height and width
@mixin cubeDimensions() {
  $dimensions: getMapCubeProps($keyDimensions);
  @include utility.orientationBpProps($dimensions);
}
// Z axis offsets for cube faces
@mixin cubeZOffsets() {
  $orientations: getMapCubeProps($keyConstruction);
  @each $orientation, $map in $orientations {
    @include utility.orientationBpProps($orientations);
  }
}
// Height, width, and translations
@mixin cubeBaseProps() {
  @include cubeDimensions();
  $locations: getMapCubeProps($keyLocation);
  @include utility.orientationBpProps($locations);
}
// Add classes that rotate the Menu Cube when applied
// - animated due to transition set on transform
@mixin cubeFaceRotationClasses($cubeZOffset) {
  // &.show-front {
  // }
  &.show-back {
    transform: #{$cubeZOffset} rotateY(-180deg);
  }
  &.show-right {
    transform: #{$cubeZOffset} rotateY(-90deg);
  }
  &.show-left {
    transform: #{$cubeZOffset} rotateY(90deg);
  }
  &.show-top {
    transform: #{$cubeZOffset} rotateX(-90deg);
  }
  &.show-bottom {
    transform: #{$cubeZOffset} rotateX(90deg);
  }
}
// Loop through the set of orientations and create set of orientation-breakpoint classes
@mixin cubeFaceRotationClassesMap() {
  @include utility.orientationBpEach($cubePositionZTranslations) using
    ($bp, $val) {
    // Content for @content of mixin
    @include utility.media-breakpoint-gte($bp) {
      @include cubeFaceRotationClasses($val);
    }
  }
}
// Create 6 faces of cube and translate into position
@mixin cubeFaces($cubeSize) {
  $cubeFaceOffset: getCubeFaceOffset($cubeSize);
  &.front {
    transform: rotateY(0deg) translateZ(#{$cubeFaceOffset});
  }
  &.back {
    transform: rotateY(180deg) translateZ(#{$cubeFaceOffset});
  }
  &.right {
    transform: rotateY(90deg) translateZ(#{$cubeFaceOffset});
    background-color: utility.$color-green;
  }
  &.left {
    transform: rotateY(-90deg) translateZ(#{$cubeFaceOffset});
    background-color: utility.$color-gunmetal;
  }
  &.top {
    transform: rotateX(90deg) translateZ(#{$cubeFaceOffset});
    background-color: utility.$color-blue;
  }
  &.bottom {
    transform: rotateX(-90deg) translateZ(#{$cubeFaceOffset});
    background-color: utility.$color-green-black;
  }
}
// Loop over all orientations/breakpoints and define cube faces for breakpoint
@mixin cubeFacesMap() {
  @each $orientation, $map in $cubeSizes {
    @media screen and (orientation: #{$orientation}) {
      @each $bp, $val in $map {
        @include utility.media-breakpoint-gte($bp) {
          @include cubeFaces($val);
        }
      }
    }
  }
}
// Style a selection section on the front cube face (menu)
@mixin cubeFaceSection($color, $bgColor) {
  background-color: $bgColor;
  svg {
    path {
      fill: $color;
    }
  }
}
//
// Wrapping class for a 3d animated cube that rotates upon click
// - Sets the perspective of the cube overall
//
.menu-cube-container {
  position: relative;
  // Value that controls how accentuated the 3d effect of the content looks
  // - Smaller values cause more distortion
  // - The distance the "viewer" from the content origin at 0,0,0
  perspective: 1000px;

  // Inform the browser of changing properties for better performance
  will-change: transform;

  // Container that is rotated to show different faces of the cube
  .menu-cube {
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.75s ease; // timing for cube rotation on click

    // Set cube width, height, z-axis translation
    @include cubeBaseProps();

    // Create classes that rotate cube when applied on click
    @include cubeFaceRotationClassesMap();

    // Every cube face gets dimensions and translations applied
    .menu-cube-face {
      position: absolute;
      @include cubeDimensions();
      // Create sides of cube
      @include cubeFacesMap();

      // Front face of cube divided into 4 clickable squares that represent menu options
      // - Portfolio, Services, About, Contact
      &.front {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0;

        > button {
          display: flex;
          justify-content: center;
          align-items: center;
          outline-offset: -1vw;
          border-radius: 0;
          img,
          svg {
            width: 60%;
          }
          &:hover {
            svg {
              path {
                fill: utility.$color-pink;
              }
            }
          }
        }
        .portfolio {
          @include cubeFaceSection(
            utility.$color-gunmetal,
            utility.$color-blue
          );
        }
        .services {
          @include cubeFaceSection(
            utility.$color-green-black,
            utility.$color-green
          );
        }
        .about {
          @include cubeFaceSection(
            utility.$color-blue,
            utility.$color-gunmetal
          );
        }
        .contact {
          @include cubeFaceSection(
            utility.$color-green,
            utility.$color-green-black
          );
        }
      }
      &.top {
        .menu-cube-face-content {
          h2 {
            color: utility.$color-blue;
            background-color: utility.$color-gunmetal;
          }
        }
      }
      &.right {
        .menu-cube-face-content {
          h2 {
            color: utility.$color-green;
            background-color: utility.$color-green-black;
          }
        }
      }
      &.bottom {
        .menu-cube-face-content {
          h2 {
            color: utility.$color-green-black;
            background-color: utility.$color-green;
          }
        }
      }
      &.left {
        .menu-cube-face-content {
          h2 {
            color: utility.$color-gunmetal;
            background-color: utility.$color-blue;
          }
        }
      }

      &-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        button.section-header {
          display: block;
          width: 100%;
          padding: 0;
          border: none;
          outline-offset: -3px;
          h2 {
            margin: 0;
            padding: 1vw;
            text-align: center;
          }
        }
        nav {
          flex: 1;
          
          ul {
            list-style-type: none;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            margin: 0;
            padding: 0;
            li {
              flex: 1;
              padding: 1vw;
              button {
                display: block;
                border: none;
                text-transform: none;
              }
            }
          }
        }
      }
    }
  }
}
